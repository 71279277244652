/*----------------------------------*\
	#reset
\*----------------------------------*/

/**
 *	Basic reset
 */

html,body{
	margin:0;
	line-height:1;
	text-rendering:optimizeLegibility; // http://usabilitypost.com/2012/11/06/optimize-legibility/
}
h1,h2,h3,h4,h5,h6,p,fieldset,input,button,textarea,blockquote,dl,dt,dd{
	margin:0;
	padding:0;
	font-size:100%;
	font-weight: normal;
}
p{
	margin-bottom: 16px;
}
ul{
	margin: 0;
	padding: 0;
}
a:active,
a:hover,
input,
textarea {
  outline: 0;
}
strong, b{
	font-weight: normal;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,{
	text-decoration: none;
}
img,fieldset,input,button,textarea {
  border: 0;
}
input,textarea{
	box-sizing:border-box;
	margin-right: -4px; // Remove the spacing between inline-block elements (http://css-tricks.com/fighting-the-space-between-inline-block-elements/)
}
button{
	background:none;
}