/*----------------------------------*\
	#button
\*----------------------------------*/
/**
 *	Button
 *	
 */

button, .btn, input[type="button"]{
	display: inline-block;
	border: 1px solid;
	cursor: pointer;
	border: 1px solid map-get($colors, base);
	font-size: $font_base;
	padding: 6px 15px;
	outline: none;
	font-family: 'maison_neuelight';
}
.btn-small{
	padding: 9px 24px;
}
.btn-medium{
	padding: 12px 45px;
}
.btn-large{
	padding: 15px 75px;
}
.btn-block{
	padding: 13px 45px;
	display: block;
    width: 100%;
}
.btn-rounded-s{
	@include border-radius(10px);
}
.btn-rounded-m{
	@include border-radius(25px);
}
@mixin btn-colors($color){
	.btn-#{$color} {
		border-color: map-get($colors, $color);
		color: map-get($colors, $color);
		@include transition( background-color 450ms ease, color 450ms ease );
		&:hover{
			background-color: map-get($colors, $color);
			color: #fff;
		}
	}
	.btn-filled-#{$color} {
		background-color: map-get($colors, $color);;
		border-color: map-get($colors, $color);
		@include transition( background-color 450ms ease, border-color 450ms ease );
		@if ( lightness( map-get($colors, $color) ) < 60 ){
			color : #fff;
		}
		@else{
			color : #404040;
		}
		&:hover{
			border-color: darken( map-get($colors, $color), 8% );
			background-color: darken( map-get($colors, $color), 8% );
		}
	}
}

@include btn-colors(base);
@include btn-colors(gray);
@include btn-colors(primary);
@include btn-colors(success);
@include btn-colors(warning);
@include btn-colors(danger);