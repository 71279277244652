@font-face {
    font-family: 'maison_neuebold';
    src: url('../fonts/maisonneue-bold-webfont.eot');
    src: url('../fonts/maisonneue-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/maisonneue-bold-webfont.woff2') format('woff2'),
         url('../fonts/maisonneue-bold-webfont.woff') format('woff'),
         url('../fonts/maisonneue-bold-webfont.ttf') format('truetype'),
         url('../fonts/maisonneue-bold-webfont.svg#maison_neuebold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'maison_neuelight';
    src: url('../fonts/maisonneue-light-webfont.eot');
    src: url('../fonts/maisonneue-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/maisonneue-light-webfont.woff2') format('woff2'),
         url('../fonts/maisonneue-light-webfont.woff') format('woff'),
         url('../fonts/maisonneue-light-webfont.ttf') format('truetype'),
         url('../fonts/maisonneue-light-webfont.svg#maison_neuelight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'playfair_displayitalic';
    src: url('../fonts/playfairdisplay-italic-webfont.eot');
    src: url('../fonts/playfairdisplay-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/playfairdisplay-italic-webfont.woff2') format('woff2'),
         url('../fonts/playfairdisplay-italic-webfont.woff') format('woff'),
         url('../fonts/playfairdisplay-italic-webfont.ttf') format('truetype'),
         url('../fonts/playfairdisplay-italic-webfont.svg#playfair_displayitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'playfair_displayregular';
    src: url('../fonts/playfairdisplay-regular-webfont.eot');
    src: url('../fonts/playfairdisplay-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/playfairdisplay-regular-webfont.woff2') format('woff2'),
         url('../fonts/playfairdisplay-regular-webfont.woff') format('woff'),
         url('../fonts/playfairdisplay-regular-webfont.ttf') format('truetype'),
         url('../fonts/playfairdisplay-regular-webfont.svg#playfair_displayregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

/*----------------------------------*\
	#font-size
\*----------------------------------*/

body{
	font-family: 'playfair_displayregular';
	color: map-get($colors, base);
	font-size:$font_base;
    line-height:1.35;
}


/**
 *	Setup selector
 */
$selector:'font-size';

/**
 *	Setup base values
 *	$font_base is defined in _base.scss however you may
 *	override this value by redefining it here if required
 */

$font_s:$font_base+3;
$font_m:$font_base+2;
$font_l:$font_base+14;

$h1:$font_base+12;
$h2:$font_base+8;
$h3:$font_base+4;
$h4:$font_base+2;
$h5:$font_base;

$font_lead:$font_base+4;
/**
 *	Setup font sizes
 */

p.lead, .lead p{
    font-family: 'playfair_displayitalic';
    &:after{
        font-family: 'streamline';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
        content: "\f08a";
        display: block;
        margin-top: 20px;
    }
}

h1, .h1{
    font-size:$h1;
}
h2, .h2{
    font-size:$h2;
}
h3, .h3{
    font-size:$h3;
}
h4, .h4{
    font-size:$h4;
}
h5, .h5{
    font-size:$h5;
}
p.lead, .lead p{
    font-size:$font_lead;
}
@media #{$md}{
    h1, .h1{
        font-size:44px;
    }
    h2, .h2{
        font-size:36px;
    }
    h3, .h3{
        font-size:28px;
    }
    h4, .h4{
        font-size:22px;
    }
    h5, .h5{
        font-size:18px;
    }
    p.lead, .lead p{
        font-size: 22px;
    }
}
@media #{$lg}{
	h1, .h1{
        font-size:52px;
    }
    h2, .h2{
        font-size:40px;
    }
    h3, .h3{
        font-size: 32px;
    }
    h4, .h4{
        font-size: 26px;
    }
    h5, .h5{
        font-size:22px;
    }
    p.lead, .lead p{
        font-size: 24px;
    }
}
@media #{$xl}{
	h1, .h1{
        font-size:64px;
    }
    h2, .h2{
        font-size:44px;
    }
    h3, .h3{
        font-size:34px;
    }
    h4, .h4{
        font-size:28px;
    }
    h5, .h5{
        font-size:24px;
    }
    p.lead, .lead p{
        font-size: 28px;
    }
}

p{
	font-size:$font_base;
    line-height: 1.75;
}
@media #{$xl-br}{
   p{
        font-size:$font_base+3;
    }
}
h1.site-name{
	font-size: 26px;
	line-height:1;
	text-transform: uppercase;
}
.post-info span{
    font-size: 14px;
    margin: 0 5px;
}
.text-center{
    text-align:center;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.page-title a{
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
    h1,h2,h3,h4,h5,h6{
        display: inline;
    }
}