/*----------------------------------*\
	#caps
\*----------------------------------*/
/**
 *	Defines a class which can be used on text
 *	elements to make them uppercase
 */

.caps{
	text-transform:uppercase;
}