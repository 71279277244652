.fill-base {
	color: map-get($colors, white);
}
@each $color, $hash in $colors {
	.text-#{$color} {
		color: $hash;
	}
	.fill-#{$color} {
		background: $hash;
	}
}

a {
	color: map-get($colors, base);
}