/*----------------------------------*\
	#input
\*----------------------------------*/
/**
 *	Input
 *	
 *	Input fields
 */

input{
	border: 1px solid;
	font-size: $font_base;
	padding: $spacing_base;
}