@-webkit-keyframes ngdialog-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ngdialog-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes ngdialog-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ngdialog-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ngdialog {
  box-sizing: border-box;
}

.ngdialog *,
.ngdialog *:before,
.ngdialog *:after {
  box-sizing: inherit;
}

.ngdialog {
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ngdialog.ngdialog-disabled-animation,
.ngdialog.ngdialog-disabled-animation .ngdialog-overlay,
.ngdialog.ngdialog-disabled-animation .ngdialog-content {
  -webkit-animation: none!important;
  animation: none!important;
}

.ngdialog-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadein 0.5s;
  animation: ngdialog-fadein 0.5s;
}

.ngdialog-no-overlay {
  pointer-events: none;
}

.ngdialog.ngdialog-closing .ngdialog-overlay {
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadeout 0.5s;
  animation: ngdialog-fadeout 0.5s;
}

.ngdialog-content {
  background: white;
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadein 0.5s;
  animation: ngdialog-fadein 0.5s;
  pointer-events: all;
}

.ngdialog.ngdialog-closing .ngdialog-content {
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadeout 0.5s;
  animation: ngdialog-fadeout 0.5s;
}

.ngdialog-close:before {
  font-family: 'Helvetica', Arial, sans-serif;
  content: '\00D7';
  cursor: pointer;
}

html.ngdialog-open,
body.ngdialog-open {
  overflow: hidden;
}


/*
* Theme Full
*/
@-webkit-keyframes ngdialog-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes ngdialog-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes ngdialog-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@keyframes ngdialog-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
.ngdialog.ngdialog-theme-full{
  .ngdialog-overlay{
    background-color: map-get($colors, gray);
  }
  .ngdialog-content{
    -webkit-animation: ngdialog-flyin .5s;
    animation: ngdialog-flyin .5s;
    position: relative;
    z-index: 2;
    background-color: map-get($colors, gray);
    padding: 120px 15px;
    display:table;
    height: 100vh;
    width:100%;
    .dialog-centered{
      width:100%;
      display:table-cell;
      vertical-align: middle;
    }
    .content p{
      font-size: 16px;
      line-height: 33px;
      font-family: 'maison_neuelight';
      margin-bottom: 22px;
    }
    .lead p{
      font-size: inherit;
      line-height: inherit;
      margin-bottom: 32px;
      font-family: 'maison_neuelight';
    }
  }
  .ngdialog-close{
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 40px;
    width: 40px;
    height: 44px;
    line-height: 1;
    text-align: center;
  }
}
.ngdialog.ngdialog-theme-full.ngdialog-closing .ngdialog-content {
  -webkit-animation: ngdialog-flyout .5s;
  animation: ngdialog-flyout .5s;
}
@media #{$md} {
  .ngdialog.ngdialog-theme-full{
    .ngdialog-content{
      .content p{
        font-size: 20px;
        line-height: 43px;
        margin-bottom: 32px;
      }
    }
  }  
}


/*
* Theme default
*/

.ngdialog.ngdialog-theme-default {
  padding-bottom: 160px;
  padding-top: 160px;
}

.ngdialog.ngdialog-theme-default.ngdialog-closing .ngdialog-content {
  -webkit-animation: ngdialog-flyout .5s;
  animation: ngdialog-flyout .5s;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  -webkit-animation: ngdialog-flyin .5s;
  animation: ngdialog-flyin .5s;
  background: #f0f0f0;
  border-radius: 5px;
  color: #444;
  font-family: 'Helvetica',sans-serif;
  font-size: 1.1em;
  line-height: 1.5em;
  margin: 0 auto;
  max-width: 100%;
  padding: 1em;
  position: relative;
  width: 450px;
}

.ngdialog.ngdialog-theme-default .ngdialog-close {
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:before {
  background: transparent;
  border-radius: 3px;
  color: #bbb;
  content: '\00D7';
  font-size: 26px;
  font-weight: 400;
  height: 30px;
  line-height: 26px;
  position: absolute;
  right: 3px;
  text-align: center;
  top: 3px;
  width: 30px;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:hover:before,
.ngdialog.ngdialog-theme-default .ngdialog-close:active:before {
  color: #777;
}

.ngdialog.ngdialog-theme-default .ngdialog-message {
  margin-bottom: .5em;
}

.ngdialog.ngdialog-theme-default .ngdialog-input {
  margin-bottom: 1em;
}

.ngdialog.ngdialog-theme-default .ngdialog-input textarea,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="text"],
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="password"],
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="email"],
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="url"] {
  background: #fff;
  border: 0;
  border-radius: 3px;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0 0 .25em;
  min-height: 2.5em;
  padding: .25em .67em;
  width: 100%;
}

.ngdialog.ngdialog-theme-default .ngdialog-input textarea:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="text"]:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="password"]:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="email"]:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="url"]:focus {
  box-shadow: inset 0 0 0 2px #8dbdf1;
  outline: none;
}

.ngdialog.ngdialog-theme-default .ngdialog-buttons {
  *zoom: 1;
}

.ngdialog.ngdialog-theme-default .ngdialog-buttons:after {
  content: '';
  display: table;
  clear: both;
}

.ngdialog.ngdialog-theme-default .ngdialog-button {
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  float: right;
  font-family: inherit;
  font-size: .8em;
  letter-spacing: .1em;
  line-height: 1em;
  margin: 0 0 0 .5em;
  padding: .75em 2em;
  text-transform: uppercase;
}

.ngdialog.ngdialog-theme-default .ngdialog-button:focus {
  -webkit-animation: ngdialog-pulse 1.1s infinite;
  animation: ngdialog-pulse 1.1s infinite;
  outline: none;
}

@media (max-width: 568px) {
  .ngdialog.ngdialog-theme-default .ngdialog-button:focus {
    -webkit-animation: none;
    animation: none;
  }
}

.ngdialog.ngdialog-theme-default .ngdialog-button.ngdialog-button-primary {
  background: #3288e6;
  color: #fff;
}

.ngdialog.ngdialog-theme-default .ngdialog-button.ngdialog-button-secondary {
  background: #e0e0e0;
  color: #777;
}