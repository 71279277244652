/*----------------------------------*\
	#list
\*----------------------------------*/
/**
 *	List
 *	
 *	Define some styles which can be used when a list
 *	should not appear as a standard list (i.e. bulleted)
 */

/**
 *	Removes the list-style and padding
 */
.list-ui{
	list-style:none;
	padding:0;
	margin:0;
}

/**
 *	Styles the list items to display in a row
 */
.list-inline{
	li{
		display:inline-block;
	}
}
.tag-list{
	list-style: none;
	padding: 0;
	margin: 10px 0;
	li{
		display: inline-block;
		vertical-align: middle;
		margin-left:5px;
		margin-right:5px;
		margin-bottom: 15px;
		a{
			text-decoration: none;
			font-size: 12px;
			padding: 2px 10px;
			border: 1px solid;
			background-color: #384155;
			border-color: #384155;
			color: #fff;
			-webkit-border-radius: 15px;
			-moz-border-radius: 15px;
			border-radius: 15px;
			font-family: 'maison_neuelight';
			text-transform: uppercase;
		}
		a[style]{
			color: #fff;
		}
	}
}
.related-item{
	.tag-list{
		li{
			margin-left:3px;
			margin-right:3px;
			margin-bottom: 5px;
		}
	}
}
.archive-item{
	.tag-list{
		li{
			margin-left:3px;
			margin-right:3px;
			margin-bottom: 5px;
		}
	}
}
.full-category-list{
	text-align: center;
	width: 100%;
	float: left;
	padding: 0 10px;
	margin-top: 30px;
	margin-bottom: 30px;
	.tag-list{
		margin: 30px 0;
	}
}
@media #{$lg} {
	.full-category-list{
		padding: 0 10px;
		border-bottom: 1px solid #E0E0E0;
		.tag-list{
			margin: 30px 0;
		}
	}
}