body{
	background-color: #FAFAF1;
}
.site-footer{
	width: 100%;
	float: left;
	position: relative;
	text-align: center;
	font-family: 'maison_neuelight';
	padding: 80px 40px;
	line-height: 2;
	a{
		text-decoration: none;
		border-bottom: 1px solid;
		margin: 0 5px;
	}
}
.content-row{
	width:100%;
	max-width: 880px;
	margin-left:auto;
	margin-right:auto;
	padding-left: 15px;
	padding-right: 15px;
	.lead{
		text-align: center;
	}
	h3{
		    text-align: center;
    margin-bottom: 26px;
    font-family: 'maison_neuelight';
    text-transform: uppercase;
    letter-spacing: 2.76;
	}
	a{
		color: #F97272;
		text-decoration: none;
	}
}
.featured-page-image{
	width: 100%;
	margin-bottom: 30px;
}
.contact-info{
	padding: 20px 0;
	.contact-item{
		padding: 15px 15px;
	}
	h2{
		margin-bottom: 20px;
	}
	p{
		font-family: 'maison_neuelight';
	}
	a{
		color: #F97272;
		text-decoration: none;
	}
}
@media #{$min-pad}{
	.featured-page-image{
		margin-bottom: 50px;
	}
	.contact-info{
		padding: 70px 0;
		.contact-item{
			padding: 25px 15px;
		}
		h2{
			margin-bottom: 20px;
		}
		p{
			font-family: 'maison_neuelight';
		}
	}
}
@media #{$xl-br}{
	.contact-info{
		padding: 70px 0;
	}
}
.post-content{
	width: 100%;
	float: left;
	padding-top: 20px;
	padding-bottom: 60px;
	background-color: #fff;
	.lead{
		text-align:center;
		padding: 20px 15px 30px 15px;
	}
	.the-content{
		padding-left: 15px;
		padding-right: 15px;
		img{
			display: block;
			margin: 0 auto;
			margin-bottom: 30px;
			margin-top: 30px;
			min-width:80%;
			&.wp-smiley{
				min-width: auto;
			}
		}
		p{
			width:100%;
			max-width: 700px;
			margin-left:auto;
			margin-right:auto;
			img{
				display: initial;
				float: none;
				margin: 0;
			}
		}
		a{
			color: #F97272;
			text-decoration: none;
		}
		h3{
			text-align: center;
			margin-bottom: 16px;
    		margin-top: 65px;
			font-family: 'maison_neuelight';
			text-transform: uppercase;
			letter-spacing: 2.76;
		}
	}
	.ingredients{
		padding-left: 15px;
		padding-right: 15px;
		text-align: center;
		.ingredient-list{
			max-width: 450px;
			margin: 0 5px 30px 5px;
			display: inline-block;
			padding: 40px 10px;
			border: 1px solid #FFC5C4;
			text-align:left;
			ul{
				list-style:none;
				padding: 10px 0;
				li{
					border-bottom: 1px solid #D6D6D6;
					padding: 10px;
					display: table;
					width: 100%;
					&:last-child{
						border-bottom:none;
					}
					span{
						display: table-cell;
						vertical-align: top;
						&.amount{
							text-align:right;
							min-width: 80px;
						}
						&.ingredient{
							font-family: 'maison_neuelight';
						}
					}
				}
			}
			.ingredient-list-title{
				font-family: 'maison_neuelight';
				text-transform: uppercase;
				font-size: 21px;
				display: block;
				text-align: center;
			}
			.ingredient-list-title-help{
				font-size: 25px;
				line-height: 1.35;
				font-family: 'playfair_displayitalic';
				font-style: italic;
				display: block;
				text-align: center;
			}
		}
	}
}

.post-pagination{

}
.related-posts{
	width: 100%;
	float: left;
	padding: 50px 0;
	font-size: 0;
	.related-item{
		font-size: 16px;
		display: inline-block;
		vertical-align: top;
		padding: 0;
		width: 100%;
		text-align: center;
		color: #fff;
		.related-item-wrap{
			position: relative;
		}
		.related-item-content-wrap{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			background-color: rgba(0, 0, 0, 0.2);
			padding: 10px;
			-webkit-transition: background-color 650ms ease;
			   -moz-transition: background-color 650ms ease;
			    -ms-transition: background-color 650ms ease;
			     -o-transition: background-color 650ms ease;
			        transition: background-color 650ms ease;
		}
		.related-item-content{
			width: 100%;
			height: 100%;
		    color: #fff;
		   	display: table;
		   	border: 1px solid transparent;
			-webkit-transition: border-color 650ms ease;
			   -moz-transition: border-color 650ms ease;
			    -ms-transition: border-color 650ms ease;
			     -o-transition: border-color 650ms ease;
			        transition: border-color 650ms ease;
		}
		.related-item-content-inner{
	   		display: table-cell;
	   		vertical-align: middle;
		}
		.related-post-title{
			font-size: 25px;
			position: relative;
	    	-webkit-transition: all 650ms ease;
			    -moz-transition: all 650ms ease;
			    -ms-transition: all 650ms ease;
			    -o-transition: all 650ms ease;
			    transition: all 650ms ease;
	    	a{
	    		display: inline-block;
	    		position: relative;
				color: #fff;
			}
	    }

		img{
			width: 100%;
			display: block;
		}
		.post-info{
			-webkit-transition: all 650ms ease;
			    -moz-transition: all 650ms ease;
			    -ms-transition: all 650ms ease;
			    -o-transition: all 650ms ease;
			    transition: all 650ms ease;
		}
		&:hover{
			.related-item-content{
				border-color: #fff;
			}
			.related-item-content-wrap{
				background-color: rgba(0, 0, 0, 0);
			}
			.related-post-title{
				text-shadow: 1px 1px 1px #404040;
			}
			.post-info{
				text-shadow: 1px 1px 1px #404040;
			}
		}
	}
}
.category-grid{
	width: 100%;
	float: left;
	padding: 50px 0 0 0;
	font-size: 0;
	.category-item{
		font-size: 16px;
		display: inline-block;
		vertical-align: top;
		padding: 0;
		width: 50%;
		text-align: center;

		.category-item-wrap{
			position: relative;
		}
		img{
			width: 100%;
			display: block;
		}
		.category-item-content-wrap{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			padding: 10px;

			a {
				display: block;
			}

			.category-item-content {
				width: 100%;
				height: 100%;
			    color:#fff;
			   	display: table;
			   	border: 1px solid transparent;
			   	-webkit-transition: border-color 650ms ease;
			   	-moz-transition: border-color 650ms ease;
			    -ms-transition: border-color 650ms ease;
			    -o-transition: border-color 650ms ease;
			    transition: border-color 650ms ease;

			   	.category-item-content-inner {
			   		display: table-cell;
			   		vertical-align: middle;
			   		padding: 0 10px;

				    .category-post-title {
				    	font-size: 16px;
		    			line-height: 1.35;
						text-shadow: 1px 1px 1px #404040;
				    }
				}
			}
		}
		&:hover{
			.category-item-content{
				border-color: #fff;
			}
		}
	}
}
@media #{$min-pad} {

	.post-content{
		.lead{
			width: 70%;
			margin-left: auto;
			margin-right: auto;
			padding-top: 40px;
			padding-bottom: 50px;
		}
		.the-content{
			h3{
				text-align: center;
				margin-bottom: 26px;
				font-family: 'maison_neuelight';
				text-transform: uppercase;
				letter-spacing: 2.76;
			}
			img{
				margin-bottom: 40px;
				margin-top: 40px;
			}
		}
		.ingredients{
			.ingredient-list{
				margin: 0 20px 70px 20px;
				padding: 60px 10px;
				ul{
					padding: 10px 60px;
					li{
						span{
							&.ingredient{
								font-size: 17px;
							}
						}
					}
				}
			}
		}
	}
	.related-posts{
		.related-item{
			width: 50%;
		}
	}
	.category-grid{
		.category-item{
			.category-item-content-wrap{
				.category-item-content{
				   	.category-item-content-inner{
				   		display: table-cell;
				   		vertical-align:middle;
				   		padding: 0 30px;
					    .category-post-title{
					    	font-size: 25px;
					    	line-height: 1.35;
					    }
					}
				}
			}
		}
	}
}
@media #{$lg} {
	.category-grid{
		.category-item{
			padding: 10px;
		}
	}
	.related-posts{
		.related-item{
			padding: 10px;
		}
	}
}
@media #{$xl} {
	.related-posts{
		.related-item{
			width: 33.3333%;
		}
	}
}
@media #{$xl-br} {
	.related-posts{
		.related-item{
			.related-post-title{
		    	font-size: 30px;
		    }
		}
	}
	.category-grid{
		.category-item{
			width: 33.3333%;
			.category-item-content-wrap{
				.category-item-content{
				   	.category-item-content-inner{
					    .category-post-title{
					    	font-size: 35px;
					    }
					}
				}
			}
		}
	}
}
.post-pagination{
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
	.next-post{
		width:50%;
		float: right;
	}
	.prev-post{
		width:50%;
		float: left;
	}
	.pagination-item{
		position: relative;
		display: block;
		text-align: center;
		padding: 0;
	}
	.pagination-item-content{
		position: relative;
	}
	.page-dir{
		font-family: 'maison_neuelight';
		padding: 10px;
	}
	a{
		text-decoration: none;
		outline: none;
	}
	.post-pagination-text-wrap{
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top:0;
		z-index: 1;
		background-color: rgba(0,0,0,0.2);
		-webkit-transition: background-color 650ms ease;
			   -moz-transition: background-color 650ms ease;
			    -ms-transition: background-color 650ms ease;
			     -o-transition: background-color 650ms ease;
			        transition: background-color 650ms ease;
	}
	.post-pagination-text{
		display: table;
		width: 100%;
		height: 100%;
		padding: 10px;
		text-align: center;
		color: #fff;
	}
	.pagination-title{
		display: table-cell;
		vertical-align: middle;
		font-size: 16px;
		border: 1px solid transparent;
		-webkit-transition: border-color 650ms ease;
			   -moz-transition: all 650ms ease;
			    -ms-transition: all 650ms ease;
			     -o-transition: all 650ms ease;
			        transition: all 650ms ease;

	}
	img{
		width: 100%;
		display: block;
	}
	span{
		display: block;
	}
	.pagination-item:hover{
		.post-pagination-text-wrap{
			background-color: rgba(0,0,0,0);
		}
		.pagination-title{
			border-color: #fff;
			text-shadow: 1px 1px 1px #404040;
		}

	}
}
@media #{$min-pad} {
	.post-pagination{
		.pagination-title{
			font-size: 25px;
		}
	}
}
@media #{$xl} {
	.post-pagination{
		.pagination-item{
			padding: 10px;
		}
	}
}
.search-query{
	width: 100%;
	float: left;
	position: relative;
	padding: 40px 10px;
	text-align: center;
	span{
		font-size: 25px;
	}
}
.search-archive{
	width: 100%;
	float: left;
	position: relative;
	font-size: 0;
	.search-item{
		font-size: 16px;
		width:100%;
		display: inline-block;
		vertical-align: top;
		padding: 10px;
		margin-bottom: 30px;
		.search-item-content{
			text-align: center;
		}
		*{
			text-decoration: none;
		}
		&:hover{
			h2{
				text-decoration: underline;
			}
		}
	}
}
.no-result{
	width: 100%;
	float: left;
	position: relative;
	text-align:center;
}
.archive-list{
	width: 100%;
	float: left;
	position: relative;
	text-align: center;
	.archive-list-item{
		width: 100%;
	}
}
.archive-items{
	width: 100%;
	float: left;
	position: relative;
	font-size: 0;
	text-align: center;
	.archive-item{
		font-size: 16px;
		width: 100%;
		display: inline-block;
		vertical-align: top;
		zoom: 1; /* Fix for IE7 */
		*display: inline; /* Fix for IE7 */
		position: relative;
		text-align: center;
		padding: 0;
		&:nth-child(1){
			padding-top: 0 !important;
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
		img{
			width: 100%;
			display: block;
		}
		a{
			display: block;
		}
		.archive-item-wrap{
			position: relative;
		}
		.archive-item-content-wrap{
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			padding: 10px;
		}
		.archive-item-content{
			width: 100%;
			height: 100%;
			display: table;
			padding: 10px;
			border: 1px solid transparent;
			-webkit-transition: border-color 400ms ease;
			   -moz-transition: border-color 400ms ease;
			    -ms-transition: border-color 400ms ease;
			     -o-transition: border-color 400ms ease;
			        transition: border-color 400ms ease;
		}
		.archive-item-content-inner{
			display: table-cell;
			vertical-align: middle;
			color: #fff;
			a{
				color: #fff;
			}
		}
		.arhive-post-title{
			font-size: 25px;
			-webkit-transition: all 650ms ease;
			-moz-transition: all 650ms ease;
			-ms-transition: all 650ms ease;
			-o-transition: all 650ms ease;
			transition: all 650ms ease;
			text-shadow: 2px 2px 2px rgba(0, 0, 0, .7);

			a {
				display: inline-block;
				position: relative;
			}
		}
		.post-info {
			-webkit-transition: all 650ms ease;
			-moz-transition: all 650ms ease;
			-ms-transition: all 650ms ease;
			-o-transition: all 650ms ease;
			transition: all 650ms ease;
			text-shadow: 1px 1px 1px #404040;
		}

		&:hover {
			.archive-item-content {
				border-color: #fff;
			}
		}
	}
}
@media #{$min-pad} {
	.archive-items{
		.archive-item{
			.arhive-post-title{
				font-size: 35px;
			}
			.arhive-post-title{
				font-size: 25px;
			}
		}
	}
}
@media #{$lg} {
	.archive-items{
		.archive-item{
			padding: 10px;
			&:nth-child(1){
				.arhive-post-title{
					font-size: 35px;
				}
			}
			&:nth-child(n+2){
				width: 50%;
				padding: 10px;
				.arhive-post-title{
					font-size: 25px;
				}
			}
			&:nth-child(2){
				margin-top: -80px;
			}
			&:nth-child(3){
				margin-top: -80px;
			}
		}
	}
}
@media #{$xl} {
	.archive-items{
		width: 100%;
		float: left;
		position: relative;
		font-size: 0;
		.archive-item{
			&:nth-child(1){
				.arhive-post-title{
					font-size: 50px;
				}
			}
			&:nth-child(n+4){
				width: 33.3333%;
			}
			&:nth-child(2){
				width:33.333333%;
				margin-top: -80px;
			}
			&:nth-child(3){
				width:33.333333%;
				margin-top: -80px;
			}
			&:nth-child(4){
				width:33.333333%;
				margin-top: -80px;
			}
			&:nth-child(5){
				width: 50%;
				.arhive-post-title{
					font-size: 35px;
				}
			}
			&:nth-child(6){
				width: 50%;
				.arhive-post-title{
					font-size: 35px;
				}
			}
			&:nth-child(n+7){
				width:33.333333%;
			}
		}
	}
}
@media #{$xl-br} {
	.archive-items{
		width: 100%;
		float: left;
		position: relative;
		font-size: 0;
		.archive-item{
			&:nth-child(1){
				.arhive-post-title{
					font-size: 64px;
				}
			}
			&:nth-child(5){
				.arhive-post-title{
					font-size: 40px;
				}
			}
			&:nth-child(6){
				.arhive-post-title{
					font-size: 40px;
				}
			}
		}
	}
}
.custom-archive-items{
	width: 100%;
	float: left;
	position: relative;
	font-size: 0;
	.archive-item{
		font-size: 16px;
		width: 100%;
		display: inline-block;
		vertical-align: top;
		zoom: 1; /* Fix for IE7 */
		*display: inline; /* Fix for IE7 */
		position: relative;
		text-align: center;
		padding: 0;
		img{
			width: 100%;
			display: block;
		}
		a{
			display: block;
		}
		.archive-item-wrap{
			position: relative;
		}
		.archive-item-content-wrap{
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: rgba(0, 0, 0, 0.2);
			opacity: 1;
			-webkit-transition: background-color 650ms ease;
			   -moz-transition: background-color 650ms ease;
			    -ms-transition: background-color 650ms ease;
			     -o-transition: background-color 650ms ease;
			        transition: background-color 650ms ease;
			padding: 10px;

		}
		.archive-item-content{
			width: 100%;
			height: 100%;
			display: table;
			padding: 10px;

		}
		.archive-item-content-inner{
			display: table-cell;
			vertical-align: middle;
			color: #fff;
			a{
				color: #fff;
			}
		}
		.arhive-post-title{
			font-size: 21px;
		}
	}
}
@media #{$min-pad} {
	.search-archive{
		.search-item{
			width:50%;
		}
	}
	.custom-archive-items{
		.archive-item{
				.arhive-post-title{
					font-size: 35px;
				}

		}
	}
}
@media #{$lg} {
	.custom-archive-items{
		.archive-item{
			padding: 10px;
			&:nth-child(1){
				.arhive-post-title{
					font-size: 35px;
				}
			}
			&:nth-child(n+2){
				width: 50%;
				.arhive-post-title{
					font-size: 25px;
				}
			}
		}
	}
}
@media #{$xl} {
	.search-archive{
		.search-item{
			width:33.3333%;
		}
	}
	.custom-archive-items{
		width: 100%;
		float: left;
		position: relative;
		font-size: 0;
		.archive-item{
			&:nth-child(1){
				.arhive-post-title{
					font-size: 50px;
				}
			}
			&:nth-child(2){
				width: 50%;
				.arhive-post-title{
					font-size: 35px;
				}
			}
			&:nth-child(3){
				width: 50%;
				.arhive-post-title{
					font-size: 35px;
				}
			}
			&:nth-child(n+4){
				width: 33.3333%;
			}
		}
	}
}
@media #{$xl-br} {
	.custom-archive-items{
		width: 100%;
		float: left;
		position: relative;
		font-size: 0;
		.archive-item{
			&:nth-child(1){
				.arhive-post-title{
					font-size: 64px;
				}
			}
			&:nth-child(2){
				.arhive-post-title{
					font-size: 40px;
				}
			}
			&:nth-child(3){
				.arhive-post-title{
					font-size: 40px;
				}
			}
		}
	}
}
.category-nav{
	min-height: 80px;
    width: 100%;
    float: left;
    display: table;
	ul{
		list-style: none;
		margin: 0;
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		padding: 20px 15px;
		li{
			display: inline-block;
			vertical-align: middle;
			font-family: 'maison_neuelight';
			margin-right: 20px;

			a{
				text-decoration: none;
				display: inline-block;
				padding: 2px 0;
				margin-bottom: 5px;
				position: relative;
				&:after{
					content: '';
					display: block;
					background-color: #404040;
					position: absolute;
					height: 1px;
					width: 100%;
					left: 0;
					bottom: 0;
					-webkit-transition: all 450ms ease;
					-moz-transition: all 450ms ease;
					-ms-transition: all 450ms ease;
					-o-transition: all 450ms ease;
					transition: all 450ms ease;
				}
				&:hover:after{
					width: 0;
					left: 50%;
				}
			}
			&.active{
				font-family: 'maison_neuebold';
				a{
					&:after{
						display: none;
					}
				}
			}
		}
	}
}
.post-info{
	font-family: 'maison_neuelight';
}
.custom-heading-font{
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	text-indent: -9999px;
}
.category-custom-heading{
	background-image: url('../images/Ida_Gran-Jansen_Kategorier.svg');
}
.recommend-custom-heading{
	background-image: url('../images/Ida_Gran-Jansen_Anbefaler.svg');
}
.comments{
	width: 100%;
	float: left;
	position: relative;
}
.comment-wrap{
	width: 100%;
	max-width: 800px;
	padding: 15px;
	margin: 20px auto 0 auto;
}
.load-more{
	width: 100%;
	float: left;
	padding: 40px 10px;
	text-align:center;
	text-transform: uppercase;
	.btn{
		position: relative;
		border: 1px solid #404040;
		width: 120px;
		height: 120px;
		border-radius: 50%;
		line-height: 113px;
		font-size: 14px;
		&.loading{
			color: transparent;
			border-top-color: transparent;
			border-bottom-color: transparent;
			-webkit-animation: ld 1s ease infinite;
			-moz-animation: ld 1s ease infinite;
			-o-animation: ld 1s ease infinite;
			animation: ld 1s ease infinite;
			/* &:before{
			    content: '';
			    display: inline-block;
			    position: absolute;
			    background: transparent;
			    border: 1px solid #fff;
			    border-top-color: transparent;
			    border-bottom-color: transparent;
			    border-radius: 50%;
			    box-sizing: border-box;
			    top: 50%;
			    left: 50%;
			    margin-top: -12px;
			    margin-left: -12px;
			    width: 24px;
			    height: 24px;
			    -webkit-animation: ld 1s ease infinite;
			    -moz-animation: ld 1s ease infinite;
			    -o-animation: ld 1s ease infinite;
			    animation: ld 1s ease infinite;
			} */
		}
	}
}
@-webkit-keyframes ld {
0%   { transform: rotate(0deg) scale(1); }
100% { transform: rotate(360deg) scale(1); }
}
@-moz-keyframes ld {
0%   { transform: rotate(0deg) scale(1); }
100% { transform: rotate(360deg) scale(1); }
}
@-o-keyframes ld {
0%   { transform: rotate(0deg) scale(1); }
100% { transform: rotate(360deg) scale(1); }
}
@keyframes ld {
0%   { transform: rotate(0deg) scale(1); }
100% { transform: rotate(360deg) scale(1); }
}