/*----------------------------------*\
	#grid
\*----------------------------------*/

/**
 *	Setup the row
 */
.container{
	float: left;
	width: 100%;
}
.page-container{
	padding: 0;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    background-color: #fff;
}
@media only screen and (min-width: 769px)  {
	.page-container{
		border: 1px solid #e0e0e0;
	}
}
.min-window, .view{
	min-height: 100vh;
}
.min-half-window{
	min-height: 50vh;
}
 /**
 *	Setup the row
 */
.row{
	width: 100%;
	max-width: $max_row_width;
	margin: 0 auto;
	&:after{
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
}
.small-row{
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	&:after{
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
}

/**
 *	Ensure boxes placed in a row sit next
 *	to each other and don't flow to next row
 */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before, *:after, *{
	box-sizing:border-box;
}

/**
 *	Setup the columns
 */
.sw-12,.sw-11,.sw-10,.sw-9,.sw-8,.sw-7,.sw-6,.sw-5,.sw-4,.sw-3,.sw-2,.sw-1,
.mw-12,.mw-11,.mw-10,.mw-9,.mw-8,.mw-7,.mw-6,.mw-5,.mw-4,.mw-3,.mw-2,.mw-1,
.lw-12,.lw-11,.lw-10,.lw-9,.lw-8,.lw-7,.lw-6,.lw-5,.lw-4,.lw-3,.lw-2,.lw-1,
.xlw-12,.xlw-11,.xlw-10,.xlw-9,.xlw-8,.xlw-7,.xlw-6,.xlw-5,.xlw-4,.xlw-3,.xlw-2,.xlw-1{
	position:relative;
	float:left;
	display:inline;
	min-height:1px;
}
.left{
	float: left;
}
.right{
	float: right;
}
.sw-centered {
	margin: 0 auto;
	float: none;
	display: block;
	&:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
}

@mixin grid($size_var) {
	@for $i from 1 through 12 {
	    $w: ( 100/(12/$i) );

	    .#{$size_var}-#{$i} {
	        width: $w *1%;
	    }
	}
}

@include grid('sw');

@media #{$md}{
	.mw-centered {
		margin: 0 auto;
		float: none;
		display: block;
		&:after {
			visibility: hidden;
			display: block;
			font-size: 0;
			content: " ";
			clear: both;
			height: 0;
		}
	}
	
	@include grid('mw');
}

@media #{$min-pad}{
	.pad-centered {
		margin: 0 auto;
		float: none;
		display: block;
		&:after {
			visibility: hidden;
			display: block;
			font-size: 0;
			content: " ";
			clear: both;
			height: 0;
		}
	}
	@include grid('pad');
}

@media #{$lg}{
	.lw-centered {
		margin: 0 auto;
		float: none;
		display: block;
		&:after {
			visibility: hidden;
			display: block;
			font-size: 0;
			content: " ";
			clear: both;
			height: 0;
		}
	}
	@include grid('lw');
}

@media #{$xl}{
	.xlw-centered {
		margin: 0 auto;
		float: none;
		display: block;
		&:after {
			visibility: hidden;
			display: block;
			font-size: 0;
			content: " ";
			clear: both;
			height: 0;
		}
	}
	@include grid('xlw');
}

/**
 *	block
 */
 .block {
	 display: block;
 }
 
/**
 *	inline-block module
 */

.inline-element-wrap {
	font-size: 0;
	overflow: hidden; 
	.inline-element {
		display: inline-block;
	 	font-size: 16px; 
	  float: none !important;
	}
}