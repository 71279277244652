/*----------------------------------*\
	#responsive-image
\*----------------------------------*/
/**
 *	Nothing complicated here, simply creates a Bootstrap-esque
 *	responsive image class so images scale on browser width
 */

.img-responsive {
	display:block;
	max-width:100%;
}

img {
	max-width:100%;
	height: auto;
}

img.featured_image {
	width: 100%;
}

.featured_image_background {
	height: 30vh;
	max-height: 650px;
	width: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	img {
		opacity: 0;
		max-height: 100%;
		width: 100%;
		height: 100%;
	}
}

@media only screen and (min-width: 769px)  {
	.featured_image_background {
		height: 60vh;
		margin-top: -33px;
	}
}
