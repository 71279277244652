/*----------------------------------*\
	#align
\*----------------------------------*/
/**
 *	Helper classes which may be applied to
 *	elements to style their alignment
 */

/**
 *	Applies to all content inside the element
 */
.sm-align-center{
	text-align:center;
}
	@media #{$md}{
		.md-align-center{
			text-align:center;
		}
	}
	@media #{$lg}{
		.lg-align-center{
			text-align:center;
		}
	}
	@media #{$xl}{
		.xl-align-center{
			text-align:center;
		}
	}

.sm-align-left{
	text-align:left;
}
	@media #{$md}{
		.md-align-left{
			text-align:left;
		}
	}
	@media #{$lg}{
		.lg-align-left{
			text-align:left;
		}
	}
	@media #{$xl}{
		.xl-align-left{
			text-align:left;
		}
	}

.sm-align-right{
	text-align:right;
}
	@media #{$md}{
		.md-align-right{
			text-align:right;
		}
	}
	@media #{$lg}{
		.lg-align-right{
			text-align:right;
		}
	}
	@media #{$xl}{
		.xl-align-right{
			text-align:right;
		}
	}


.sm-align-justify{
	text-align:justify;
}
	@media #{$md}{
		.md-align-justify{
			text-align:justify;
		}
	}
	@media #{$lg}{
		.lg-align-justify{
			text-align:justify;
		}
	}
	@media #{$xl}{
		.xl-align-justify{
			text-align:justify;
		}
	}

.sm-v-align-top {
	vertical-align: top;
}
	@media #{$md}{
		.md-v-align-top{
			vertical-align: top;
		}
	}
	@media #{$lg}{
		.lg-v-align-top{
			vertical-align: top;
		}
	}
	@media #{$xl}{
		.xl-v-align-top{
			vertical-align: top;
		}
	}

.sm-v-align-middle {
	vertical-align: middle;
}
	@media #{$md}{
		.md-v-align-middle{
			vertical-align: middle;
		}
	}
	@media #{$lg}{
		.lg-v-align-middle{
			vertical-align: middle;
		}
	}
	@media #{$xl}{
		.xl-v-align-middle{
			vertical-align: middle;
		}
	}

.sm-v-align-bottom {
	vertical-align: bottom;
}
	@media #{$md}{
		.md-v-align-bottom{
			vertical-align: bottom;
		}
	}
	@media #{$lg}{
		.lg-v-align-bottom{
			vertical-align: bottom;
		}
	}
	@media #{$xl}{
		.xl-v-align-bottom{
			vertical-align: bottom;
		}
	}


/**
 *	Applies to the element itself
 */
.element-center{
	margin-left:auto;
	margin-right:auto;
	display:block;
	float:none;
}