/*----------------------------------*\
	#padding
\*----------------------------------*/
/**
 *	Helper classes which may be applied to any
 *	type of element to add padding
 *
 *	Usage:
 *	<div class="sm-pad-left lg-pad">
 *		Some content
 *	</div>
 *	This will add left padding to element on <480 screen
 *	and top/right/bottom/left padding to element at >768 screen

.sm-pad-left_sm

 */
 

/**
 *	Setup selector
 */
$selector:'pad';

/**
 *	Setup base values
 *	$spacing_base is defined in _base.scss however you may
 *	override this value by redefining it here if required
 */
$spacing_md:$spacing_base+5;
$spacing_lg:$spacing_base*2;
$spacing_xl:$spacing_base*8;

/**
 *	clear padding left and right (sm)
 */
 
@mixin padding($size_var) {
	.#{$size_var}-clear-col-#{$selector}-1 {
		margin-left: -$spacing_base;
		margin-right: -$spacing_base;
	}
	.#{$size_var}-clear-col-#{$selector}-2 {
		margin-left: -$spacing_md;
		margin-right: -$spacing_md;
	}
	.#{$size_var}-clear-col-#{$selector}-3 {
		margin-left: -$spacing_lg;
		margin-right: -$spacing_lg;
	}
	.#{$size_var}-clear-col-#{$selector}-4 {
		margin-left: -$spacing_xl;
		margin-right: -$spacing_xl;
	}


	/**
	 *	Padding all
	 */
	.#{$size_var}-#{$selector}-1{
		padding:$spacing_base;
	}
	.#{$size_var}-#{$selector}-2{
		padding:$spacing_md;
	}
	.#{$size_var}-#{$selector}-3{
		padding:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-4{
		padding:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-null{
		padding:0;
	}

	/**
	 *	Padding col
	 */
	.#{$size_var}-#{$selector}-col-1{
		padding-left:$spacing_base;
		padding-right:$spacing_base;
	}
	.#{$size_var}-#{$selector}-col-2{
		padding-left:$spacing_md;
		padding-right:$spacing_md;
	}
	.#{$size_var}-#{$selector}-col-3{
		padding-left:$spacing_lg;
		padding-right:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-col-4{
		padding-left:$spacing_xl;
		padding-right:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-col-null{
		padding-left:0;
		padding-right:0;
	}

	/**
	 *	Padding top (sm)
	 */
	.#{$size_var}-#{$selector}-top-1{
		padding-top:$spacing_base;
	}
	.#{$size_var}-#{$selector}-top-2{
		padding-top:$spacing_md;
	}
	.#{$size_var}-#{$selector}-top-3{
		padding-top:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-top-4{
		padding-top:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-top-null{
		padding:0;
	}

	/**
	 *	Padding right (sm)
	 */
	.#{$size_var}-#{$selector}-right-1{
		padding-right:$spacing_base;
	}
	.#{$size_var}-#{$selector}-right-2{
		padding-right:$spacing_md;
	}
	.#{$size_var}-#{$selector}-right-3{
		padding-right:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-right-4{
		padding-right:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-right-null{
		padding:0;
	}

	/**
	 *	Padding bottom (sm)
	 */
	.#{$size_var}-#{$selector}-bottom-1{
		padding-bottom:$spacing_base;
	}
	.#{$size_var}-#{$selector}-bottom-2{
		padding-bottom:$spacing_md;
	}
	.#{$size_var}-#{$selector}-bottom-3{
		padding-bottom:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-bottom-4{
		padding-bottom:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-bottom-null{
		padding:0;
	}

	/**
	 *	Padding left (sm)
	 */
	.#{$size_var}-#{$selector}-left-1{
		padding-left:$spacing_base;
	}
	.#{$size_var}-#{$selector}-left-2{
		padding-left:$spacing_md;
	}
	.#{$size_var}-#{$selector}-left-3{
		padding-left:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-left-4{
		padding-left:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-left-null{
		padding:0;
	}
}

@include padding('sm');

@media #{$md}{
	@include padding('md');
}
@media #{$lg}{
	@include padding('lg');
}
@media #{$xl}{
	@include padding('xl');
}