/*----------------------------------*\
	#breakpoint
\*----------------------------------*/
/**
 *	Define some global breakpoints. Note that we
 *	do not need to define 'sm' because this is
 * 	implicit given the mobile-first nature of the
 *	framework
 */

$md:'only screen and(min-width:480px)';
$min-pad:'only screen and(min-width:768px)';
$max-pad:'only screen and(max-width:768px)';
$lg:'only screen and(min-width:769px)';
$xl:'only screen and(min-width:1024px)';

/*Extra custom*/
$xl-br:'only screen and(min-width:1280px)';
$xxl-br:'only screen and(min-width:1560px)';

/*----------------------------------*\
	#base
\*----------------------------------*/

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin transition( $params... ) {
  -webkit-transition: $params;
     -moz-transition: $params;
      -ms-transition: $params;
       -o-transition: $params;
          transition: $params;
}

/**
 *	Define base colours
 */

$colors: (
	base: #404040,
	gray: #eeeeee,
	light: #f8f8f8,
	white: #ffffff,
	primary: #3498db,
	success: #2ecc71,
	warning: #f39c12,
	danger: #c0392b
);

$tag_colors: (
	blue: #6D7DA1,
	pink: #EA9AA5,
	dark_blue: #384155,
	brown: #AA7179,
);

/**
 *	Define max row width
 */
$max_row_width: 1440px;

/**
 *	Define base (interior and exterior) spacing
 */
$spacing_base:10px;

/**
 *	Define base font-size
 */
$font_base:16px;

// Pinterest button z-index fix
span[data-pin-href] {
	z-index: 98 !important;
}
