.ng-enter{
    opacity: 0;
    -webkit-transition: all 500ms ease;
       -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
         -o-transition: all 500ms ease;
            transition: all 500ms ease;
    &.ng-enter-active{
        opacity: 1;
    }
}
.ng-leave{
    opacity: 1;
    -webkit-transition: all 500ms ease;
       -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
         -o-transition: all 500ms ease;
            transition: all 500ms ease;
    &.ng-leave-active{
        opacity: 0;
    }
}

div[fade-in=slide], img[fade-in=slide], span[fade-in=slide], figure[fade-in=slide], a[fade-in=slide] {
    &.in-view[fade-in=slide] {
        -moz-animation-duration: .6s;
        -webkit-animation-duration: .6s;
        animation-duration: .6s;
        -moz-animation-name: slidein;
        -webkit-animation-name: slidein;
        animation-name: slidein;
        -moz-animation-iteration-count: 1;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }
}

div[fade-in=fade], img[fade-in=fade], span[fade-in=fade], figure[fade-in=fade], a[fade-in=fade] {
    &.in-view[fade-in=fade] {
        -moz-animation-duration: .6s;
        -webkit-animation-duration: .6s;
        animation-duration: .6s;
        -moz-animation-name: fadein;
        -webkit-animation-name: fadein;
        animation-name: fadein;
        -moz-animation-iteration-count: 1;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }
}
//slidein
@-moz-keyframes slidein {
  from {
    opacity: 0;
    -moz-transform: translate(0,90px);
  }
      
  to {
    opacity: 1;
    -moz-transform: translate(0,0);
  }
}

@-webkit-keyframes slidein {
  from {
    opacity: 0;
    -webkit-transform: translate(0,90px);
  }
      
  to {
    opacity: 1;
    -webkit-transform: translate(0,0);
  }
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: translate(0,90px);
  }
      
  to {
    opacity: 1;
    transform: translate(0,0);
  }
}

//fadein
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
      
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
      
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
      
  to {
    opacity: 1;
  }
}