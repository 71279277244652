/*----------------------------------*\
	#margin
\*----------------------------------*/
/**
 *	Helper classes which may be applied to any
 *	type of element to add margins
 *
 *	Usage:
 *	<h2 class="lg-margin-bottom">
 *		Heading
 *	</h2>
 *	This will add a bottom margin of 30px to
 *	the h2 heading element at >768px screen width
 */

/**
 *	Setup selector
 */
$selector:'margin';

/**
 *	Setup base values
 *	$spacing_base is defined in _base.scss however you may
 *	override this value by redefining it here if required
 */
$spacing_md:$spacing_base*2;
$spacing_lg:$spacing_base*3;
$spacing_xl:$spacing_base*4;

/**
 *	Setup margins
 *
 *	Margins are flexible and can be configured by both
 *	screen size and actual value, for example:
 *
 *	<div class="sm_margin-bottom_md xl_margin-bottom_lg">
 *		Some content
 *	</div>
 *	This would mean:
 *		On a small screen, a medium margin-bottom would be applied 
 *		On an extra-large screen, a large margin-bottom would be applied
 *
 *	This allows finer control over the spacing of 
 *	elements at various breakpoints.
 */

@mixin margin($size_var) {
	/**
	 *	Margin all
	 */
	.#{$size_var}-#{$selector}-1{
		margin:$spacing_base;
	}
	.#{$size_var}-#{$selector}-2{
		margin:$spacing_md;
	}
	.#{$size_var}-#{$selector}-3{
		margin:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-4{
		margin:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-null{
		margin:0;
	}

	/**
	 *	Margin col
	 */
	.#{$size_var}-#{$selector}-col-1{
		margin-left:$spacing_base;
		margin-right:$spacing_base;
	}
	.#{$size_var}-#{$selector}-col-2{
		margin-left:$spacing_md;
		margin-right:$spacing_md;
	}
	.#{$size_var}-#{$selector}-col-3{
		margin-left:$spacing_lg;
		margin-right:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-col-4{
		margin-left:$spacing_xl;
		margin-right:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-col-null{
		margin-left:0;
		margin-right:0;
	}

	/**
	 *	Margin top (sm)
	 */
	.#{$size_var}-#{$selector}-top-1{
		margin-top:$spacing_base;
	}
	.#{$size_var}-#{$selector}-top-2{
		margin-top:$spacing_md;
	}
	.#{$size_var}-#{$selector}-top-3{
		margin-top:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-top-4{
		margin-top:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-top-null{
		margin:0;
	}

	/**
	 *	Margin right (sm)
	 */
	.#{$size_var}-#{$selector}-right-1{
		margin-right:$spacing_base;
	}
	.#{$size_var}-#{$selector}-right-2{
		margin-right:$spacing_md;
	}
	.#{$size_var}-#{$selector}-right-3{
		margin-right:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-right-4{
		margin-right:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-right-null{
		margin:0;
	}

	/**
	 *	Margin bottom (sm)
	 */
	.#{$size_var}-#{$selector}-bottom-1{
		margin-bottom:$spacing_base;
	}
	.#{$size_var}-#{$selector}-bottom-2{
		margin-bottom:$spacing_md;
	}
	.#{$size_var}-#{$selector}-bottom-3{
		margin-bottom:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-bottom-4{
		margin-bottom:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-bottom-null{
		padding:0;
	}

	/**
	 *	Margin left (sm)
	 */
	.#{$size_var}-#{$selector}-left-1{
		margin-left:$spacing_base;
	}
	.#{$size_var}-#{$selector}-left-2{
		margin-left:$spacing_md;
	}
	.#{$size_var}-#{$selector}-left-3{
		margin-left:$spacing_lg;
	}
	.#{$size_var}-#{$selector}-left-4{
		margin-left:$spacing_xl;
	}
	.#{$size_var}-#{$selector}-left-null{
		margin:0;
	}
}

@include margin('sm');

@media #{$md}{
	@include margin('md');
}
@media #{$lg}{
	@include margin('lg');
}
@media #{$xl}{
	@include margin('xl');
}