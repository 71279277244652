$spacing_md:$spacing_base+5;
$spacing_lg:$spacing_base*2;
$spacing_xl:$spacing_base*8;


/**
 *	page top section
 */
.page-top{
 	width:100%;
 	margin: 0 auto;
 	float: left;
 	position: relative;
 	padding: 0;
 	.page-top-wrapper{
 		width:100%;
 		padding: 20px 15px 0 15px;
 		margin: 0 auto;
 		position: relative;
 		text-align: center;
 		&:after {
			content: '';
			display: block;
			clear: both;
		}
		.page-title{
			padding-bottom: 15px;
			max-width: 800px;
    		margin: 0 auto;
		}
		.post-info{
			padding-bottom: 10px;
		}
 	}
}
@media #{$md}{
	.page-top{
	 	.page-top-wrapper{
	 		width:100%;
	 		padding: 50px 15px 0 15px;
	 	}
 	}
}
@media #{$xl}{
	.page-top-wrapper{
		max-width:70%;
	}
}
/**
 *	Billboard horizontal
 */

.billboard-horizontal {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	.billboard-wrapper {
		width: 100%;
		max-width: $max_row_width;
		margin: 0 auto;
		font-size: 0;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
	.billboard-resources,
	.billboard-details {
		display: block;
	}
	.billboard-resources { 
		text-align: center;
		max-width: 75%;
		margin: 0 auto;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
	.billboard-details {
		padding: $spacing_lg $spacing_md;
	}
	.billboard-lead {
		font-size: 19px;
		line-height: 1.5;
		margin-bottom: $spacing_lg;
	}
	.billboard-header {
		margin-bottom: $spacing_lg;
	}
}

@media #{$xl}{

	.billboard-horizontal .billboard-resources { 
		text-align: center;
		max-width: 100%;
	}
	.billboard-horizontal .billboard-details {
		padding: 0 $spacing_xl;
	}
	.billboard-horizontal .billboard-resources,
	.billboard-horizontal .billboard-details {
		display: inline-block;
		vertical-align: middle;
		width: 50%;
	}
}



/**
 *	Billboard vertical
 */

.billboard-vertical {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	
	.billboard-wrapper {
		width: 100%;
		max-width: $max_row_width;
		margin: 0 auto;
		font-size: 0;
		padding: $spacing_xl 0;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
	
	.billboard-resources,
	.billboard-details {
		width: 100%;
		margin: 0 auto;
		display: block;
		text-align: center;
		padding: 0 $spacing_lg;		
	}
	

	.billboard-resources {
		max-width: 75%;
	}
	
	.billboard-details {
		padding: $spacing_lg $spacing_md;
	}

	.billboard-lead {
		font-size: 19px;
		line-height: 1.5;
		margin-bottom: $spacing_lg;
	}

	.billboard-header {
		margin-bottom: $spacing_lg;
	}
}

@media #{$md}{
	.billboard-vertical .billboard-resources,
	.billboard-vertical .billboard-details {
		max-width: 75%;
	}
}


@media #{$lg}{ 
	.billboard-vertical .billboard-resources,
	.billboard-vertical .billboard-details {
		width: 100%;
		max-width: 45%;
		margin: 0 auto;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
	.billboard-vertical .billboard-details.size-2{
		max-width: 75%;
	}
}

/**
 *	Brackets
 */

.brackets {
	padding: $spacing_lg 0;
	.brackets-wrapper {
		width: 100%;
		max-width: $max_row_width;
		margin: 0 auto;
		padding: $spacing_lg $spacing_lg;
		display: block;		
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}
	.bracket-resources,
	.bracket-details {
		display: block;
		text-align: center;
	}
	.brackets .bracket-resources {
		margin-bottom: $spacing_lg;	
	}

	.bracket-lead {
		width: 75%;
		margin: 0 auto;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}

	.bracket-wrapper {
		padding: $spacing_lg $spacing_lg;
		display: block;
		margin: 0 auto;
		width: 100%;
	}
	
	&.columns-2 .bracket { 
		float: left;
		width: 100%;
	}
}

@media #{$md} {
	.brackets .bracket-lead {
		width: 100%;
	}
	
	.brackets.columns-2 .bracket {
		width: 50%;
		float: left;
	}
	
	.brackets.columns-3 .bracket {
		width: 33.3333%;
		float: left;
	}
}
@media #{$lg} {
	.brackets {
		padding: $spacing_lg 0;
	}
	.brackets .bracket-wrapper {
		max-width: 75%;
	}
}

/**
 *	Editorial
 */

.editorial {
	padding: $spacing_xl 0;
	.editorial-wrapper {
		width: 100%;
		max-width: 1300px;
		margin: 0 auto;
		padding: 0 $spacing_lg;
	}
	.editorial-resources {
		margin: 0;
	}
	.editorial-header,
	.editorial-resources {
		margin-bottom: $spacing_lg;
	}
}

@media #{$lg} {		
	
	.editorial.columns-2 .editorial-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 50px;
    -moz-column-gap: 50px;
    column-gap: 50px;
	}
	.editorial.columns-3 .editorial-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 50px;
    -moz-column-gap: 50px;
    column-gap: 50px;
	}
}