@media (max-width: 1023px)  {
	.menu-placeholder{
		width:100%;
		float: left;
		height:80px;
	}
	.menu{
		position: fixed;
		width:100%;
		float: left;
		height:80px;
		display:table;
		top: 0;
		border-bottom: 1px solid #E0E0E0;
		background-color: #fff;
		z-index: 99;
		nav{
			display:table-cell;
			vertical-align:middle;
			z-index: 1;
			position: relative;
	    	background-color: #fff;
			.nav-wrap{
				width: 100%;
				max-width: $max_row_width;
				margin: 0 auto;
				font-size: 0;
				.main-nav-wrap{
					padding: 0 10px;
					background-color: #fff;
					position: relative;
					z-index: 2;
				}
				.mobile-menu-trigger{
					width: 25%;
					display: inline-block;
					vertical-align: middle;
					text-align: center;
					font-family: 'maison_neuelight';
					font-size: 16px;
					cursor: pointer;
					padding: 10px;
					span{
						border-bottom: 1px solid #404040;
					}
				}
				.site-name{
					display: inline-block;
					vertical-align: middle;
					padding-left: 10px;
					padding-right: 10px;
					width: 50%;
					height: 80px;
					background-image: url('../images/Ida_Gran-Jansen_logo.svg');
					background-repeat: no-repeat;
					background-position: center center;
					background-size: calc(100% - 20px) 45px;
					text-indent: -9999px;
					font-size: 16px;
				}
				.mobile-search-trigger{
					width: 25%;
					display: inline-block;
					vertical-align: middle;
					text-align: center;
					height: 40px;
					line-height: 20px;
					cursor: pointer;
					padding: 10px;
					&:before{
						font-size: 25px;
						border: 1px solid #404040;
						border-radius: 50%;
						padding: 7px;
					}
				}
				ul{
					list-style:none;
					display: none;
					vertical-align:middle;
					padding: 0;
					padding-left: 10px;
					padding-right: 10px;
					margin:0;
					li{
						display:inline-block;
						vertical-align:middle;
						a{
							text-decoration: none;
							display: block;
							font-family: 'maison_neuelight';
							span{
								display: block;
								vertical-align: bottom;
								&.menu-icon{
									font-size: 25px;
									margin-bottom: 5px;
								}
							}
						}
					}
					&.info-nav{
						display: block;
						width: 100%;
						position: absolute;
						top: 12px;
						left: 0;
						background-color: #fff;
						text-transform: uppercase;
						text-align: center;
						padding: 20px 10px;
						height: 68px;
						border-bottom: 1px solid #E0E0E0;
						-webkit-transition: top 450ms ease, opacity 0s ease 450ms;
							   -moz-transition: top 450ms ease, opacity 0s ease 450ms;
							    -ms-transition: top 450ms ease, opacity 0s ease 450ms;
							     -o-transition: top 450ms ease, opacity 0s ease 450ms;
							        transition: top 450ms ease, opacity 0s ease 450ms;
						opacity: 0;
						z-index: -1;
						
						.search{
							display: none;
						}
						a{
							font-size: 16px;
						}
						span{
							display: inline-block;
							vertical-align: middle;
						}
						.menu-icon{
							display: inline-block;
							margin-top: 3px;
							font-size: 24px;
							margin-right: 5px;
							margin-bottom: 0;
						}
					}
					&.main-nav{
						position: absolute;
						display: table;
						background-color: #fff;
						table-layout: fixed;
						width: 100%;
						left: 0;
						top: -20px;
						height: 100px;
						border-bottom: 1px solid #E0E0E0;
						border-top: 1px solid #E0E0E0;
						-webkit-transition: top 450ms ease, opacity 0s ease 450ms;
							   -moz-transition: top 450ms ease, opacity 0s ease 450ms;
							    -ms-transition: top 450ms ease, opacity 0s ease 450ms;
							     -o-transition: top 450ms ease, opacity 0s ease 450ms;
							        transition: top 450ms ease, opacity 0s ease 450ms;
						opacity: 0;
						z-index: -1;
						li{
							margin-left: 5px;
							margin-right: 5px;
							display: table-cell;
							text-align: center;
							.secondary-nav-wrap {
								display: none;
							    position: absolute;
							    width: 100%;   
							    left: 0;
							    top: 100px;
							    height: calc(100vh - 180px);
							    overflow-y: auto;
							    .secondary-nav{
							    	background: #fff;
							    	border-bottom: 1px solid #E8E8E8;
								    border-left: 1px solid #E8E8E8;
								    border-right: 1px solid #E8E8E8;
								    padding: 5px 20px 30px 20px;
								    margin-top: 0;
								    display: block;
								    width: 100%;
								    height: auto;
							    	li{
								    	width: 100%;
								    	text-align: left;
								    	padding: 5px 10px;
								    	border:none;
								    	text-transform: uppercase;
								    	font-family: 'maison_neuelight';
								    	display: block;
								    	&:nth-child(n+2){
								    		border-bottom: 1px solid #e0e0e0;
								    	}
								    	&:last-child{
								    		border:none;
								    	}
								    	&.show-all a{
								    		color: #F97272;
								    		font-size: 16px;
								    	}
								    }
							    }
							}
							a{
								font-size: 14px;
								padding: 5px;
								text-transform: uppercase;
							}
							&.active{
								.secondary-nav-wrap {
									display: block;
								}
							}
						}
					}
					&.main-nav-2{
						li{
							text-transform: uppercase;
							a{
								font-size: 12px;
								padding: 3px 15px;
								border:1px solid;
								-webkit-border-radius: 15px;
								-moz-border-radius: 15px;
								border-radius: 15px;
							}
							span.search{
								font-size: 12px;
								padding: 3px 15px;
								border:1px solid;
								background-color: #312E3D;
								border:1px solid #312E3D;
								color: #fff;
								-webkit-border-radius: 15px;
								-moz-border-radius: 15px;
								border-radius: 15px;
							}
						}
					}
				}
				&.menu-active{
					background-color: red;
					ul{
						&.main-nav{
							z-index: 1;
							opacity: 1;
							top: 80px;
							-webkit-transition: top 450ms ease, opacity 0s ease;
							   -moz-transition: top 450ms ease, opacity 0s ease;
							    -ms-transition: top 450ms ease, opacity 0s ease;
							     -o-transition: top 450ms ease, opacity 0s ease;
							        transition: top 450ms ease, opacity 0s ease;
						}
						&.info-nav{
							z-index: 0;
							opacity: 1;
							top: 180px;
							-webkit-transition: top 450ms ease, opacity 0s ease;
							   -moz-transition: top 450ms ease, opacity 0s ease;
							    -ms-transition: top 450ms ease, opacity 0s ease;
							     -o-transition: top 450ms ease, opacity 0s ease;
							        transition: top 450ms ease, opacity 0s ease;
						}
					}
				}
			}
		}
	}
	.search-form-wrap{
		position: absolute;
	    top: 20px;
	    width: 100%;
	    left: 0;
	    padding: 10px;
	    height: 60px;
	    background-color: #FCFCFC;
	    border-bottom: 1px solid #E0E0E0;
	    border-top: 1px solid #E0E0E0;
	    -webkit-transition: top 450ms ease, opacity 0s ease 450ms;
		   -moz-transition: top 450ms ease, opacity 0s ease 450ms;
		    -ms-transition: top 450ms ease, opacity 0s ease 450ms;
		     -o-transition: top 450ms ease, opacity 0s ease 450ms;
		        transition: top 450ms ease, opacity 0s ease 450ms;
		opacity: 0;
		z-index: -1;
	    &.active{
	    	z-index: 0;
			opacity: 1;
	    	top: 80px;
	    	-webkit-transition: top 450ms ease, opacity 0s ease;
			   -moz-transition: top 450ms ease, opacity 0s ease;
			    -ms-transition: top 450ms ease, opacity 0s ease;
			     -o-transition: top 450ms ease, opacity 0s ease;
			        transition: top 450ms ease, opacity 0s ease;
	    }
	    .search-form{
	    	margin: 0 auto;
	    	max-width: 700px;
	    	width: 90%;
	    	text-transform: uppercase;
	    	font-family: 'maison_neuelight';
	    	font-size: 0;
	    	position: relative;
	    	input[type=search]{
	    		display: inline-block;
	    		vertical-align: middle;
	    		width: calc(100% - 45px);
	    		margin-right: 10px;
	    		background-color: transparent;
	    		border:0;
	    		border-radius: 0;
	    		border-bottom: 1px solid #D8D8D8;
	    		font: 16px 'maison_neuelight';
	    		-webkit-appearance: none; /* add this */

	    	}
	    	input[type=submit]{
	    		width: 35px;
	    		height: 35px;
	   			display: inline-block;
	    		vertical-align: middle;
	    		background-color: transparent;
	    		border: none;
	    		-webkit-appearance: none; /* add this */
	    		opacity: 0;
	    		z-index: 1;
	    		font-size: 16px;
	    		cursor: pointer;
	    	}
	    	.custom-input-submit{
	    		position: absolute;
			    right: 0;
			    width: 35px;
			    height: 35px;
			    background-color: #F97272;
			    -webkit-border-radius: 20px;
	    		-moz-border-radius: 20px;
	    		border-radius: 20px;
	    		text-align: center;
	    		font-size: 16px;
	    		z-index: -1;
	    		&:before{
	    			font-family: 'streamline';
					speak: none;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;

					/* Better Font Rendering =========== */
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					content: "\e800";
					color: #fff;
					font-size: 26px;
    				line-height: 35px;
	    		}
	    	}
	    }
	}
}
@media (min-width: 1024px)  {
	.mobile-menu-trigger, .mobile-search-trigger{
		display: none;
	}
	.menu-placeholder{
		width:100%;
		float: left;
		height:120px;
	}
	.search-form-wrap{
		position: absolute;
	    top: 100%;
	    width: 100%;
	    left: 0;
	    padding: 15px 10px;
	    background-color: #FCFCFC;
	    border-bottom: 1px solid #E0E0E0;
	    -webkit-transform: translateY(-100%);
	       -moz-transform: translateY(-100%);
	        -ms-transform: translateY(-100%);
	         -o-transform: translateY(-100%);
	            transform: translateY(-100%);
	    -webkit-transition: all 450ms ease;
	       -moz-transition: all 450ms ease;
	        -ms-transition: all 450ms ease;
	         -o-transition: all 450ms ease;
	            transition: all 450ms ease;
	    &.active{
	    	-webkit-transform: translateY(0);
	       	-moz-transform: translateY(0);
	        -ms-transform: translateY(0);
	         -o-transform: translateY(0);
	            transform: translateY(0);
	    }
	    .search-form{
	    	margin: 0 auto;
	    	max-width: 700px;
	    	width: 100%;
	    	text-transform: uppercase;
	    	font-family: 'maison_neuelight';
	    	position: relative;
	    	font-size: 0;
	    	input[type=search]{
	    		display: inline-block;
	    		vertical-align: middle;
	    		width: calc(100% - 45px);
	    		margin-right: 10px;
	    		background-color: transparent;
	    		border:0;
	    		border-bottom: 1px solid #D8D8D8;
	    		font: 16px 'maison_neuelight';
	    		-webkit-appearance: none; /* add this */
	    	}
	    	input[type=submit]{
	    		width: 35px;
	    		height: 35px;
	   			display: inline-block;
	    		vertical-align: middle;
	    		background-color: transparent;
	    		border: none;
	    		-webkit-appearance: none; /* add this */
	    		opacity: 0;
	    		z-index: 1;
	    		font-size: 16px;
	    		cursor: pointer;
	    	}
	    	.custom-input-submit{
	    		position: absolute;
			    right: 0;
			    width: 35px;
			    height: 35px;
			    top: 5px;
			    background-color: #F97272;
			    -webkit-border-radius: 20px;
	    		-moz-border-radius: 20px;
	    		border-radius: 20px;
	    		text-align: center;
	    		font-size: 16px;
	    		z-index: -1;
	    		&:before{
	    			font-family: 'streamline';
					speak: none;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;

					/* Better Font Rendering =========== */
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					content: "\e800";
					color: #fff;
					font-size: 26px;
    				line-height: 35px;
	    		}
	    	}
	    }
	}
	.menu{
		position: fixed;
		width:100%;
		float: left;
		height:120px;
		display:table;
		border-bottom: 1px solid #E0E0E0;
		background-color: #fff;
		z-index: 99;
		nav{
			display:table-cell;
			vertical-align:middle;
			z-index: 1;
			position: relative;
	    	background-color: #fff;
			.nav-wrap{
				width: 100%;
				max-width: $max_row_width;
				margin: 0 auto;
				font-size: 0;
				padding: 0 20px;
				.main-nav-wrap{
					width: 250px;
					display: inline-block;
					vertical-align: middle;
				}
				.site-name{
					padding: 10px;
					display: inline-block;
					vertical-align:middle;
					background-image: url('../images/Ida_Gran-Jansen_logo.svg');
					background-repeat: no-repeat;
					background-position: left 10px center;
					width:250px;
					background-size: 230px;
					text-indent: -9999px;
				}
				ul{
					list-style:none;
					display: inline-block;
					vertical-align:middle;
					padding: 0;
					padding-left: 10px;
					padding-right: 10px;
					margin:0;
					li{
						display:inline-block;
						vertical-align:middle;
						font-size: 16px;
						position: relative;
						a{
							display: block;
							text-decoration: none;
							span{
								display: block;
								text-align:center;
								&.menu-icon{
									font-size: 28px;
									margin-bottom:10px;
								}
							}
						}
						.secondary-nav-wrap {
							display: none;
						    position: absolute;
						    width: 410px;   
						    left: 0;
						    .secondary-nav{
						    	background: #fff;
						    	border-bottom: 1px solid #E8E8E8;
							    border-left: 1px solid #E8E8E8;
							    border-right: 1px solid #E8E8E8;
							    padding: 5px 20px 30px 20px;
							    margin-top: 25px;
							    height: auto;
						    	li{
							    	width: 100%;
							    	text-align: left;
							    	padding: 10px;
							    	border:none;
							    	text-transform: uppercase;
							    	font-family: 'maison_neuelight';
							    	&:nth-child(n+2){
							    		border-bottom: 1px solid #e0e0e0;
							    	}
							    	&:last-child{
							    		border:none;
							    	}
							    	&.show-all a{
							    		color: #F97272;	
							    	}
							    }
						    }
						}
						&:hover{
							.secondary-nav-wrap {
								display: block;
							}
						}
					}
					&.info-nav{
						width:250px;
						display: inline-block;
						font-size: 16px;
						vertical-align: middle;
						li{
							margin: 0 5px;
						}
						text-align:right;
						text-transform: uppercase;
						.search{
							cursor: pointer;
							text-align:center;
							font-family: 'maison_neuelight';
							font-size: 14px;
						}
						a{
							font-family: 'maison_neuelight';
							font-size: 14px;
						}
						.menu-icon{
							display: block;
							font-size: 24px;
							margin-bottom: 5px;
						}
					}
					&.main-nav{
						width:calc(100% - 500px);
						text-align: center;
						li{
							padding-left: 25px;
						    padding-right: 25px;
						    border-right: 1px solid #e0e0e0;
							a{
								font-size: 16px;
								padding: 5px;
								font-family: 'maison_neuelight';
								text-transform: uppercase;
							}
							&:last-child{
								border-right: none;
							}
						}
					}
					&.main-nav-2{
						li{
							text-transform: uppercase;
							a{
								font-size: 12px;
								padding: 3px 15px;
								border:1px solid;
								-webkit-border-radius: 15px;
								-moz-border-radius: 15px;
								border-radius: 15px;
								font-family: 'maison_neuelight';
							}
							span.search{
								font-size: 12px;
								padding: 3px 15px;
								border:1px solid;
								background-color: #312E3D;
								border:1px solid #312E3D;
								color: #fff;
								-webkit-border-radius: 15px;
								-moz-border-radius: 15px;
								border-radius: 15px;
								font-family: 'maison_neuelight';
							}
						}
					}
				}
			}
		}
	}
}